'use client'

import {
  PaginationList,
  PaginationRepeater,
} from '@/components/utility/pagination/pagination-list'

import { type ReactNode } from 'react'
import { CategoriesMenuGrid } from '../categories-menu-grid'

export const CategoriesMenuComponent = ({
  children,
}: {
  children: ReactNode[]
}) => {
  if (children.length <= 9) {
    return <CategoriesMenuGrid>{children}</CategoriesMenuGrid>
  }

  return (
    <PaginationList
      title={''}
      seeAllLink=''
      isSkeleton={false}
      containerClass='sm:py-5 max-sm:mt-0 my-6 md:my-8'
    >
      <PaginationRepeater rows={1} repeaterMargin={0} title={'categories_menu'}>
        {children}
      </PaginationRepeater>
    </PaginationList>
  )
}
